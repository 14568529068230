





















































































import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class Solution extends Vue {
	private parentData = {
		titleName: "硬件超市",
		dec: "1000+潜在客户曝光，各种硬件一键服务接入",
		bannerImg: require("../../assets/images/banner.png"),
		img: require("../../assets/images/banner_icon3.png"),
		imgWidth: "379px",
		imgHeight: "256px",
		btnTxt: "申请接入"
	};

	private list = [
		{
			img: require("../../assets/images/product/product1.png"),
			name: "MINI2.0网关LORA",
			projectNum: 1020,
			deviceNum: 33319
		},
		{
			img: require("../../assets/images/product/product2.png"),
			name: "消防主机联网终端",
			projectNum: 1020,
			deviceNum: 33319
		},
		{
			img: require("../../assets/images/product/product3.png"),
			name: "烟雾探测警报器NB-iot",
			projectNum: 1020,
			deviceNum: 33319
		},
		{
			img: require("../../assets/images/product/product4.png"),
			name: "自动巡检远程控制设备",
			projectNum: 1020,
			deviceNum: 33319
		},
		{
			img: require("../../assets/images/product/product5.png"),
			name: "工业燃气终端",
			projectNum: 1020,
			deviceNum: 33319
		},
		{
			img: require("../../assets/images/product/product6.png"),
			name: "燃气探测警报器LORA",
			projectNum: 1020,
			deviceNum: 33319
		},
		{
			img: require("../../assets/images/product/product7.png"),
			name: "二代NB电器火灾报警",
			projectNum: 1020,
			deviceNum: 33319
		},
		{
			img: require("../../assets/images/product/product8.png"),
			name: "MINI2.0网关LORA",
			projectNum: 1020,
			deviceNum: 33319
		},
		{
			img: require("../../assets/images/product/product9.png"),
			name: "超级传感器",
			projectNum: 1020,
			deviceNum: 33319
		},
		{
			img: require("../../assets/images/product/product10.png"),
			name: "消防供水压力检测器",
			projectNum: 1020,
			deviceNum: 33319
		},
		{
			img: require("../../assets/images/product/product11.png"),
			name: "视频联动",
			projectNum: 1020,
			deviceNum: 33319
		},
		{
			img: require("../../assets/images/product/product12.png"),
			name: "一键报警器",
			projectNum: 1020,
			deviceNum: 33319
		},
		{
			img: require("../../assets/images/product/product13.png"),
			name: "独立烟感接入消防主机",
			projectNum: 1020,
			deviceNum: 33319
		}
	];

	private created() {
		this.$emit("getChildrenData", this.parentData);
	}
}
